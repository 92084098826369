import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import styled from "@emotion/styled";
import ContentTypeSelector from './ContentTypeSelector';

const VerticalStack = styled(VStack)`
  width:100%;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
`;
const FormField = styled(FormControl)`
  width:fit-content;
  max-width:50%;
`;

const CButton = styled(Button)`
  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

const EditableForm = ({ initialData = {}, onSubmit, isLoading = false }) => {
  const [formData, setFormData] = useState({
    id: '',
    content_type: '',
    object_id: '',
    template_key: '',
    enabled: false,
    fallback: false,
    content: '',
    ...initialData,
  });

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, ...initialData }));
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      const newValue = type === 'checkbox' ? checked : value;
      if (prevData[name] !== newValue) {
        setIsChanged(true);
      }
      return {
        ...prevData,
        [name]: newValue,
      };
    });
  };

  const handleContentTypeChange = ({ content_type, object_id }) => {
    setFormData((prevData) => {
      const changed = prevData.content_type !== content_type || prevData.object_id !== object_id;
      if (changed) setIsChanged(true);
      return {
        ...prevData,
        content_type,
        object_id,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setIsChanged(false);
  };

  const isFormFilled = Object.values(formData).some(
    (value) => value !== '' && value !== false
  );

  const disable = isLoading || !isChanged

  return (
    <Box
      bg={"white"}
      p={4}
      w={"100%"}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      mb={4}
      opacity={isLoading ? 0.6 : 1} // Reduce opacity when loading
      pointerEvents={isLoading ? 'none' : 'auto'} // Disable interactions when loading
    >
      <form onSubmit={handleSubmit}>
        <VerticalStack w={"100%"} spacing={4}>
          {formData.id && (
            <FormField>
              <FormLabel htmlFor="id">ID</FormLabel>
              <Input
                style={{ height: "38px" }}
                id="id"
                name="id"
                placeholder="Enter ID"
                value={formData.id}
                onChange={handleChange}
                isReadOnly
              />
            </FormField>
          )}

          <FormField>
            <ContentTypeSelector
              contentTypeId={formData.content_type}
              objectId={formData.object_id}
              onChange={handleContentTypeChange}
            />
          </FormField>

          <FormField>
            <FormLabel htmlFor="template_key">Template Key</FormLabel>
            <Input
              style={{ height: "38px" }}
              id="template_key"
              name="template_key"
              placeholder="Enter Template Key"
              value={formData.template_key}
              onChange={handleChange}
            />
          </FormField>

          <FormField display="flex" alignItems="center">
            <FormLabel htmlFor="enabled" mb="0">
              Enabled
            </FormLabel>
            <Switch
              id="enabled"
              name="enabled"
              isChecked={formData.enabled}
              onChange={handleChange}
            />
          </FormField>

          <FormField display="flex" alignItems="center">
            <FormLabel htmlFor="fallback" mb="0">
              Fallback
            </FormLabel>
            <Switch
              id="fallback"
              name="fallback"
              isChecked={formData.fallback}
              onChange={handleChange}
            />
          </FormField>

          <FormControl minW={"100%"}>
            <FormLabel htmlFor="content">Content</FormLabel>
            <Textarea
              id="content"
              name="content"
              placeholder="Enter Content"
              value={formData.content}
              onChange={handleChange}
            />
          </FormControl>

          <CButton
            disabled={disable}
            isDisabled={disable}
            mt={4}
            colorScheme="teal"
            type={disable ? "button" : "submit"}
          >
            {isLoading ? <Spinner size="sm" /> : 'Save'}

          </CButton>
        </VerticalStack>
      </form>
    </Box>
  );
};

export default EditableForm;
