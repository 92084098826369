/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { ChatIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Grid, Text, InputGroup, InputLeftElement, Input, Link as ChakraLink, Spinner } from '@chakra-ui/react';

import { listGroupMessages, listMessageDetails } from '../actions/chatVisualizerAction';
import ChatList from '../components/chats/ChatList';
import MessageChatsList from '../components/chats/MessageChatsList';
import MessageDetails from '../components/chats/MessageDetails';
import Loader from '../components/Loader';
import { formattedData } from '../components/TripTableDateSelect';

const MessagesTemplates = ({ chats, loading }) => {
  const { messageId } = useParams();
  const [activeChat, setActiveChat] = React.useState(null);
  const [specificMessageDetails, setSpecificMessageDetails] = React.useState(null);
  const [chatId, setChatId] = React.useState(null);
  const [chatName, setChatName] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState({ refresh: false });
  const [specificMessage, setSpecificMessage] = React.useState(null);
  
  const dispatch = useDispatch();
  const { messages, loading: isLoading, error, isMoreMessages, isLoadingDetails } = useSelector((state) => state.messagesVisualizerReducer);
  const { messageDetails } = useSelector((state) => state.messagesVisualizerReducer);
  //This function is responsible for setting the active chat based on the provided id and fetching the group messages for that chat using a dispatch call to listGroupMessages.
  const getCurrentChat = (id, params) => {
    setActiveChat(id);
    dispatch(listGroupMessages(id, params));
  };

  let chat = {
    chat_name: messages?.chat_name || null,
    id: messages?.chat_provider_id || null,
    messages: [],
  };

  let later_messages = messages?.context?.later_messages || [];

  later_messages.forEach((message) => {
    let date = message?.time?.split(' ')[0]; // Extract the date part from the time field
    let dateExists = chat?.messages.find((msg) => msg?.date === date);

    if (!dateExists) {
      chat?.messages?.push({ date: date, messages: [message] }); // Initialize if this date is not already in the array
    } else {
      dateExists?.messages.push(message); // Add the message to the array for this date
    }
  });

  useEffect(() => {
    if (chats?.length === 0) return;
    setActiveChat(chats?.[0]?.chat_id);
    setChatId(chats?.[0]?.chat_id);
    getCurrentChat(chats?.[0]?.chat_id, selectedOptions);
  }, [chats]);

  useEffect(() => {
    if (chats?.length === 0) return;
    setChatName(chats?.[0]?.chat_name);
    // getCurrentChat(chats?.[0]?.chat_id, selectedOptions);

    if (error && chats?.length > 0) {
      setActiveChat(chats?.[0]?.chat_id);
      setChatId(chats?.[0]?.chat_id);
    }
  }, [chats, error]);

  useEffect(() => {
    if (messageId) {
      viewSpecificMessageDetails(messageId);
    }
  }, []);

  useEffect(() => {
    if (selectedOptions.refresh === false) return;
    getCurrentChat(chatId, {
      ...selectedOptions,
      refresh: true,
    });

    () => {
      setSelectedOptions({
        refresh: false,
      });
    };
  }, [selectedOptions]);

  const viewMessageDetails = (messageId) => {
      dispatch(listMessageDetails(messageId));
  };

  const viewSpecificMessageDetails = (messageId) => {
     if (messageDetails) {  
      const specificMessage = messageDetails.find((message) => message._id === messageId);
      if (specificMessage) {
        setSpecificMessage(specificMessage);
      }
     }
     setSpecificMessageDetails(messageId);
  };

  // handleSelectChange updates the selected options for filtering based on the columnId and the selectedOption.
  // - It uses a dictionary (columFilterDictionary) to map column IDs to their respective filter keys.
  // - If a selectedOption is null, it removes the filter for that column from the state.
  // - Otherwise, it formats the selectedOption based on the columnId ('date' is formatted differently than 'time_start' and 'time_end').
  // - The new value is then set in the state, along with a refresh flag set to true.
  const handleSelectChange = (columnId, selectedOption) => {
    const columFilterDictionary = {
      date: 'date',
      time_start: 'time_start',
      time_end: 'time_end',
    };

    setSelectedOptions((prevSelectedOptions) => {
      const key = columFilterDictionary[columnId];
      if (key) {
        if (selectedOption === null) {
          const { [key]: _, ...rest } = prevSelectedOptions;
          return rest;
        } else {
          let newValue;
          if (columnId === 'date') {
            newValue = formattedData(selectedOption);
          } else if (columnId === 'time_start' || columnId === 'time_end') {
            newValue = moment(selectedOption).format('HH:mm');
          }
          return {
            ...prevSelectedOptions,
            refresh: true,
            [key]: newValue,
          };
        }
      }
      return prevSelectedOptions;
    });
  };
  // handleSearchLocation updates the selected options for filtering based on the values object, specifically for the 'media' column.
  // - It uses a dictionary (columnFilterDictionary) to map the 'media' column ID to its filter key.
  // - If the 'media' value is an empty string, it removes the filter for the 'media' column from the state.
  // - Otherwise, it iterates over the keys in the values object and updates the state with each value, setting a refresh flag to true for each.
  const handleSearchLocation = (values) => {
    const columnFilterDictionary = {
      media: 'media',
    };
    if (values.media === '') {
      setSelectedOptions((prevSelectedOptions) => {
        const { [columnFilterDictionary.media]: _, ...rest } = prevSelectedOptions;
        return rest;
      });
    } else {
      Object.keys(values).forEach((columnId) => {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          refresh: true,
          [columnFilterDictionary[columnId]]: values[columnId],
        }));
      });
    }
  };

  if (loading) {
    return <Loader />;
  }
  if (specificMessageDetails) {
    return <MessageDetails messageDetails={specificMessage} messageId={specificMessageDetails} onClose={() => setSpecificMessageDetails(null)} />;
  }
  if (chats?.length === 0 || chats === undefined) {
    return (
      <Box
        mt={10}
        pt={4}
        height={240}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        w="99.98%"
        borderRadius={5}
        position={'sticky'}
        bg="white"
        style={{
          borderRight: '1px solid #e1e1e1', // Lighter border color
          borderBottom: '1px solid #e1e1e1', // Lighter border color
        }}
      >
        <ChatIcon color="green.100" fontSize="100px" />
        <Text fontWeight="semibold" color="gray.600" mt={2}>
          No hay chats
        </Text>
      </Box>
    );
  }
  return (
    <Grid templateColumns="300px 1fr" mt={10} width={'100%'} overflowY={'hidden'} display={'flex'}>
      <Box
        position={'sticky'}
        borderRight={'2px'}
        borderColor={'gray.200'}
        px={2}
        pt={50}
        pb={10}
        height={'95vh'}
        bg="white"
        m={0}
        color="white"
        overflowY={'auto'}
        width={'25%'}
        minWidth={'350px'}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {/* <SearchMessage /> */}
        <Box display={'flex'} alignItems={'center'} borderBottom="1px solid #F7F7F7">
          <Text color="gray.800" fontSize="lg" fontWeight="bold" ml={2} mt={2}>
          <Box width="100%" mt={2} mb={4}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                value=""
                onChange={(e) => {}}
              />
            </InputGroup>
          </Box>
          </Text>
        </Box>
        {chats
          ?.filter((chat) => Object.keys(chat).length !== 0)
          ?.map((chat, index) => {
            return (
              <MessageChatsList
                activeChat={chat.chat_id === activeChat}
                getCurrentChat={() => {
                  setChatId(chat?.chat_id);
                  setChatName(chat?.chat_name);
                  getCurrentChat(chat.chat_id, {
                    ...selectedOptions,
                    refresh: false,
                  });
                }}
                key={index}
                chat={chat}
                index={index}
              />
            );
          })}
      </Box>
      {activeChat && (
      <Box width={'45%'}>
        <ChatList
          messages={chat}
          isLoading={isLoading}
          isMoreMessages={isMoreMessages}
          error={error}
          handleSelectChange={handleSelectChange}
          handleSearchLocation={handleSearchLocation}
          selectedOptions={selectedOptions}
          chatName={chatName}
          viewMessageDetails={viewMessageDetails}
          chatId={chatId}
          params={selectedOptions}
        />
      </Box>
      )}
        <Box m={0} width={'30%'} minHeight={'100%'} fontSize={'12px'}>
        <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} bg="white">
          <Box as="table" width="100%" borderCollapse="collapse">
            <Box as="thead" bg="gray.100">
              <Box as="tr">
                <Box as="th" p={2} border="1px" borderColor="gray.200">Status</Box>
                <Box as="th" p={2} border="1px" borderColor="gray.200">Run</Box>
                <Box as="th" p={2} border="1px" borderColor="gray.200">Chain</Box>
                <Box as="th" p={2} border="1px" borderColor="gray.200">Action</Box>
              </Box>
            </Box>
            {isLoadingDetails && (
              <Box as="tbody" width="100%"  justifyContent="center" alignItems="center">
                <Box as="tr">
                  <Box as="td" p={2} border="1px" borderColor="gray.200" colSpan={4} alignItems="center" justifyContent="center" textAlign="center">
                    <Spinner />
                  </Box>
                </Box>
              </Box>
            )}

            {!isLoadingDetails && !messageDetails && (
              <Box as="tbody" width="100%"  justifyContent="center" alignItems="center">
              <Box as="tr">
                <Box as="td" p={2} border="1px" borderColor="gray.200" colSpan={4} alignItems="center" justifyContent="center" textAlign="center">
                  No message details available
                </Box>
              </Box>
            </Box>
            )}
                       
            {!isLoadingDetails && messageDetails && (
            <Box as="tbody">
                {messageDetails.map((message, index) => (
                  <Box as="tr" key={index}>
                    <Box as="td" p={2} border="1px" borderColor="gray.200">{message.status}</Box>
                    <Box as="td" p={2} border="1px" borderColor="gray.200">{message.start_at_datetime}</Box>
                    <Box as="td" p={2} border="1px" borderColor="gray.200">#{message.service_chain.id}</Box>
                    <Box as="td" p={2} border="1px" borderColor="gray.200">
                      <Text style={{cursor: 'pointer'}} onClick={() => {
                        window.history.pushState({}, '', `/message-details/${message._id}`)
                        viewSpecificMessageDetails(message._id)
                      }}  >View Detail</Text>
                    </Box>
                  </Box>
              ))}
              </Box>
            )}
          </Box>
        </Box>
        </Box>
    </Grid>
  );
};

export default MessagesTemplates;
