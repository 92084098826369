import { useState } from 'react';
import { Checkbox, Box, Skeleton, Flex } from '@chakra-ui/react';

export default function AnnouncementChatList({ chats = [], selectedChats, setSelectedChats, isLoading }) {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedChats([]); // Deselect all chats
    } else {
      setSelectedChats(chats?.map((chat) => chat.id)); // Select all chats
    }
    setSelectAll(!selectAll);
  };

  const handleToggleChat = (chatId) => {
    setSelectedChats((prev) =>
      prev.includes(chatId)
        ? prev.filter((id) => id !== chatId) // Deselect if already selected
        : [...prev, chatId] // Add to selected chats
    );
  };

  if (isLoading) {
    return (
      <div className="space-y-2">
        <Skeleton height="16px" width="250px" />
        <div className="space-y-2">
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} height="16px" width="100%" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {/* Select All Checkbox */}
      {/* <div className="flex items-center space-x-2"> */}
      <Flex align="center" gap={2} mb={2}>
        <Checkbox
          isChecked={selectAll}
          onChange={handleSelectAll} // Fixed to use Chakra's `onChange`
          colorScheme="blue" // Customize color scheme
          borderColor="gray.400" // Set border for unchecked state
        />

        <label className="text-sm font-medium">
          Seleccionar todos
        </label>

      </Flex>

      {/* Chat List */}
      <Box
        maxH="400px"
        borderWidth="1px"
        borderRadius="md"
        p="2"
        overflowY="auto" // Chakra-native scrollable area
        borderColor="gray.300" // Set border for unchecked state
      >
        {chats?.map((chat) => (

          <Flex key={chat.id} align="center" gap={2} py={1}>
            <Checkbox
              isChecked={selectedChats.includes(chat.id)}
              onChange={() => handleToggleChat(chat.id)} // Fixed to use Chakra's `onChange`
              colorScheme="blue" // Customize color scheme
              borderColor="gray.400" // Set border for unchecked state
            />
            <label className="text-sm">
            {chat.channel.name || chat.client.name || "< Sin nombre >"}
            </label>
          
          </Flex>
        ))}
      </Box>

      {/* Selected Count */}
      <Flex mt={2}>
        <div className="text-sm">
          Seleccionados: {selectedChats.length} / {chats?.length}
        </div>
      </Flex>
    </div>
  );
}
