// Simulated delay to mimic network latency
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Mock data for chats
const mockChats = Array.from({ length: 131 }, (_, i) => ({
  id: `chat-${i + 1}`,
  name: `Chat ${i + 1}`,
}));

export async function fetchChats() {
  await delay(1000); // Simulate network delay
  return mockChats;
}

export async function sendAnnouncement(selectedChats, message) {
  await delay(1500); // Simulate network delay
  
  // Simulate a 10% chance of error
  if (Math.random() < 0.1) {
    throw new Error('Failed to send announcement. Please try again.');
  }

  return {
    success: true,
    message: `Announcement sent to ${selectedChats.length} chat(s)`,
    sentTo: selectedChats,
  };
}
