import { Textarea, FormControl, FormLabel } from "@chakra-ui/react"

export default function MessageComposer({ message, setMessage }) {
  return (
    <FormControl>
      <FormLabel htmlFor="announcement">Mensaje Masivo</FormLabel>
      <Textarea
        id="announcement"
        placeholder="Type your announcement here..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        minHeight="300px"
        borderColor="gray.400" // Set border for unchecked state
      />
    </FormControl>
  )
}