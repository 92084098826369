import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Box, Spinner } from '@chakra-ui/react';
import Select from 'react-select';
import { fetchContentTypes, fetchObjectsForContentType } from './../../api';
import styled from '@emotion/styled';

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  max-width: 600px;
  width: fit-content;
`

const DEBOUNCE_DELAY = 1000; // Adjust time as needed (in ms)

const ContentTypeSelector = ({ contentTypeId, objectId, onChange }) => {
  const [contentTypes, setContentTypes] = useState([]);
  const [objects, setObjects] = useState([]);
  const [loadingCT, setLoadingCT] = useState(false);
  const [loadingObj, setLoadingObj] = useState(false);

  // The actual search queries used in API calls
  const [ctSearch, setCtSearch] = useState('');
  const [objSearch, setObjSearch] = useState('');

  // The user-typed terms, debounced before setting ctSearch/objSearch
  const [searchTermCT, setSearchTermCT] = useState('');
  const [searchTermObj, setSearchTermObj] = useState('');

  // useEffect(() => {
  //   // Debounce for Content Types
  //   const ctTimer = setTimeout(() => {
  //     setCtSearch(searchTermCT);
  //   }, DEBOUNCE_DELAY);

  //   return () => clearTimeout(ctTimer);
  // }, [searchTermCT]);

  // useEffect(() => {
  //   // Debounce for Objects
  //   const objTimer = setTimeout(() => {
  //     setObjSearch(searchTermObj);
  //   }, DEBOUNCE_DELAY);

  //   return () => clearTimeout(objTimer);
  // }, [searchTermObj]);

  useEffect(() => {
    loadContentTypes(ctSearch);
  }, [ctSearch]);

  useEffect(() => {
    if (contentTypeId) {
      loadObjects(contentTypeId, objSearch);
    } else {
      setObjects([]); // Reset objects if no content type selected
    }
  }, [contentTypeId, objSearch]);

  const loadContentTypes = async (query) => {
    setLoadingCT(true);
    try {
      const data = await fetchContentTypes(query);
      setContentTypes(data);
    } catch (err) {
      console.error('Error fetching content types:', err);
      setContentTypes([]);
    } finally {
      setLoadingCT(false);
    }
  };

  const loadObjects = async (ctId, query) => {
    setLoadingObj(true);
    try {
      const data = await fetchObjectsForContentType(ctId, query);
      setObjects(data);
    } catch (err) {
      console.error('Error fetching objects:', err);
      setObjects([]);
    } finally {
      setLoadingObj(false);
    }
  };

  const handleContentTypeChange = (selectedOption) => {
    const newCTId = selectedOption ? selectedOption.value : '';
    onChange({ content_type: newCTId, object_id: '' });
  };

  const handleObjectChange = (selectedOption) => {
    const newObjId = selectedOption ? selectedOption.value : '';
    onChange({ content_type: contentTypeId, object_id: newObjId });
  };

  // Convert contentTypes and objects to react-select options
  const contentTypeOptions = contentTypes.map(ct => ({
    value: ct.id,
    label: `${ct.name} (ID: ${ct.id})`,
  }));

  const objectOptions = objects.map(obj => ({
    value: obj.id,
    label: `${obj.name} (ID: ${obj.id})`,
  }));

  // Find the currently selected content type and object
  const selectedContentType = contentTypeId
    ? contentTypeOptions.find(option => option.value === Number(contentTypeId)) || null
    : null;

  const selectedObject = objectId
    ? objectOptions.find(option => option.value === Number(objectId)) || null
    : null;

  return (
    <Container>
      <FormControl mb={2}>
        <FormLabel>Content Type</FormLabel>
        {loadingCT ? (
          <Spinner />
        ) : (
          <Select
            placeholder="Select Content Type..."
            value={selectedContentType}
            onChange={handleContentTypeChange}
            // Update searchTermCT on input change, but don't trigger search yet
            onInputChange={(inputValue) => setSearchTermCT(inputValue)}
            inputValue={searchTermCT}
            options={contentTypeOptions}
            isClearable
          />
        )}
      </FormControl>

      {contentTypeId && (
        <FormControl mb={2}>
          <FormLabel>Object</FormLabel>
          {loadingObj ? (
            <Spinner />
          ) : (
            <Select
              placeholder="Select Object..."
              value={selectedObject}
              onChange={handleObjectChange}
              // Update searchTermObj on input change, but don't trigger search yet
              onInputChange={(inputValue) => setSearchTermObj(inputValue)}
              inputValue={searchTermObj}
              options={objectOptions}
              isClearable
            />
          )}
        </FormControl>
      )}
    </Container>
  );
};

export default ContentTypeSelector;