import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import SnnipetForm from './SnnipetForm';
import { createPromptSnippet, updatePromptSnippet } from "./../../api";

const Snippets = ({ snippets = [] }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [formSnippets, setFormSnippets] = useState(snippets);
  const [showNewSnippetForm, setShowNewSnippetForm] = useState(false);
  const [loadingSnippets, setLoadingSnippets] = useState({});
  const toast = useToast();

  const handleFormSubmit = (data) => {
    // This function updates the local state after a snippet is created/updated successfully
    const existingIndex = formSnippets.findIndex(s => s.id === data.id);
    if (existingIndex !== -1) {
      // Update existing snippet
      const updated = [...formSnippets];
      updated[existingIndex] = data;
      setFormSnippets(updated);
    } else {
      // Add new snippet
      setFormSnippets((prevSnippets) => [...prevSnippets, data]);
    }

    setShowNewSnippetForm(false); // Hide empty form after submit
  };

  const handleUpsertSnippet = async (data) => {
    const snippetId = data.id || 'new';
    setLoadingSnippets(prev => ({ ...prev, [snippetId]: true }));

    try {
      let result;
      if (data?.id) {
        // Update snippet
        result = await updatePromptSnippet(data.id, data);
        toast({
          title: "Snippet Updated",
          description: `Snippet "${data.template_key}" has been updated successfully.`,
          status: "success",
          duration: 2500,
          isClosable: true,
          position: "top-right",
        });
      } else {
        // Create snippet
        result = await createPromptSnippet(data);
        toast({
          title: "Snippet Created",
          description: `Snippet "${data.template_key}" has been created successfully.`,
          status: "success",
          duration: 2500,
          isClosable: true,
          position: "top-right",
        });
      }
      // Once the API call succeeds, update the state with the returned data
      handleFormSubmit(result);
    } catch (error) {
      const errorData = error?.response?.data;
      console.log({ errorData });

      const parseError = (errorData) => Object.entries(errorData).map(([key, [value]]) => `${key}: ${value}`).join('  ')
      const errorMessage = parseError(errorData) || 'An error occurred.';
      console.error('Error upserting snippet:', errorMessage);
      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",

      });
    } finally {
      setLoadingSnippets(prev => ({ ...prev, [snippetId]: false }));
    }
  };

  return (
    <Box p={2} width="100%" bg={'gray.200'}>
      <Button
        colorScheme='blue'
        bg={'blue.800'}
        color={"white"}
        width="100%"
        onClick={onToggle}
        mb={4}
        leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      >
        {isOpen ? 'Hide Snippets' : 'Show Snippets'}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {isOpen && (
          <Box
            maxH="400px"
            overflowY="auto"
            p={4}
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
          >
            <VStack spacing={4} align="stretch">
              {formSnippets.map((snippet, index) => (
                <SnnipetForm
                  key={snippet.id || index}
                  initialData={snippet}
                  onSubmit={handleUpsertSnippet}
                  isLoading={loadingSnippets[snippet.id] || false}
                />
              ))}
              {showNewSnippetForm && (
                <SnnipetForm
                  onSubmit={handleUpsertSnippet}
                  isLoading={loadingSnippets['new'] || false}
                />
              )}
              {!showNewSnippetForm && (
                <Button
                  bg={'blue.900'}
                  colorScheme='blue'
                  color={"white"}
                  onClick={() => setShowNewSnippetForm(true)}
                  leftIcon={<AddIcon />}
                >
                  Add New Snippet
                </Button>
              )}
            </VStack>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default Snippets;