// Determine BASE_URL based on the environment
let baseUrl;
// Get the environment from the environment variables
const environment = process.env.REACT_APP_ENVIRONMENT;
// Set baseUrl based on the environment
if (environment === 'LOCAL') {
  // baseUrl = 'http://127.0.0.1';
  baseUrl = 'http://0.0.0.0:80';
  // baseUrl = 'https://api.staging.trebu.io';
} else if (environment === 'DEVELOPMENT') {
  baseUrl = 'https://api.dev.trebu.io';
} else if (environment === 'STAGING') {
  baseUrl = 'https://api.staging.trebu.io';
} else if (environment === 'PRODUCTION') {
  baseUrl = 'https://api.trebu.io';
}

// Export the BASE_URL for use in other parts of the application
export const BASE_URL = baseUrl;
