import httpClient from './utils/axiosInstance';
import {
  CHAT_VISUALIZER_FAIL,
  CHAT_VISUALIZER_GROUP_FAIL,
  CHAT_VISUALIZER_GROUP_LOADING,
  CHAT_VISUALIZER_GROUP_SUCCESS,
  CHAT_VISUALIZER_CARRIER_GROUP_FAIL,
  CHAT_VISUALIZER_CARRIER_GROUP_LOADING,
  CHAT_VISUALIZER_CARRIER_GROUP_SUCCESS,
  CHAT_VISUALIZER_LOADING,
  CHAT_VISUALIZER_SUCCESS,
  MESSAGE_DETAILS_SUCCESS,
  CHAT_LOAD_MORE_MESSAGES,
  CHAT_VISUALIZER_LOADING_DETAILS,
  // CHAT_VISUALIZER_REFRESH_LOADING,
} from '../constants/chatVisualizerConstants';
// get all chat groups and list group messages for fake wss view

//get all chat groups
export const getChatGroupVisualizer = (id) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_VISUALIZER_GROUP_LOADING });
    const { data } = await httpClient.get(`/api/v1/company/${id}/chats`);
    dispatch({
      type: CHAT_VISUALIZER_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_VISUALIZER_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//get all chat groups
export const getCarrierChatGroupVisualizer = (id) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_VISUALIZER_CARRIER_GROUP_LOADING });
    const { data } = await httpClient.get(`/api/v1/company/${id}/chats/carriers`);
    dispatch({
      type: CHAT_VISUALIZER_CARRIER_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_VISUALIZER_CARRIER_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listMessageDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_VISUALIZER_LOADING_DETAILS });
    const { data } = await httpClient.get(`/api/v1/message/history/${id}/`);
    dispatch({
      type: MESSAGE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error('error', error);
  }
};

// get messages of id groups
export const listGroupMessages = (id, params, lastId) => async (dispatch) => {
  try {
    const { refresh, ...restParams } = params;
    if (!refresh && !lastId) {
      dispatch({ type: CHAT_VISUALIZER_LOADING });
    }

    let requestUrl = `/api/v1/chats/${id}/messages/`;
    if (lastId) {
      requestUrl += `?last_id=${lastId}`;
    } 

    const { data } = await httpClient.get(requestUrl, {
      params: { ...restParams },
    });
    
    if (lastId) {
      dispatch({
        type: CHAT_LOAD_MORE_MESSAGES,
        payload: data,
      });
    } else {
      dispatch({
        type: CHAT_VISUALIZER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.error('error', error);
    dispatch({
      type: CHAT_VISUALIZER_FAIL,
      payload: 'Hubo un error!',
    });
  }
};
