/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { getUserDetails } from './actions/userActions';
import HomeScreen from './components/utils/HomeScreen';
import AppLoader from './components/utils/skeletons.jsx/AppLoader';
import AvailabilityScreen from './screens/availability';
import ChangePassword from './screens/ChangePassword';
import BidRequestGroup from './screens/bid-request/group/[token]';
import BidRequestSet from './screens/bid-request/set';
import LoginScreen from './screens/LoginScreen';
import MessagesScreen from './screens/MessagesScreen';
import NotFoundPage from './screens/NotFound';
import TripRequestListScreen from './screens/TripRequestListScreen';
import AnnouncementScreen from './screens/AnnouncementScreen';

function App() {
  const dispatch = useDispatch();
  // Retrieve user login information from the Redux store
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const redirectedToHomeScreen = useRef(false);
  // Retrieve user details from the Redux store
  const userDetailsData = useSelector((state) => state.userDetails);
  const { loading, userDetails } = userDetailsData;
  // Fetch user details if userInfo exists and userDetails are not already fetched
  useEffect(() => {
    if (!userInfo || userDetails !== undefined) return;
    dispatch(getUserDetails());
  }, [userInfo, userDetails]);

  useEffect(() => {
    if (!loading) return
    redirectedToHomeScreen.current = false
  }, [loading])

  if (loading) return <AppLoader />;
  return (
    // Set up the Router and define the application routes
    <Router>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />

        <Route
          path="/"
          element={
            userInfo ? (
              <HomeScreen userDetails={userDetails} redirectedToHomeScreen={redirectedToHomeScreen} exact />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/bid-request/set" element={userInfo ? <BidRequestSet exact /> : <Navigate to="/login" />} />
        <Route path="/admin" element={userInfo ? <MessagesScreen exact /> : <Navigate to="/login" />} />
        <Route path="/availabilities" element={userInfo ? <AvailabilityScreen exact /> : <Navigate to="/login" />} />
        <Route path="/change-password" element={userInfo ? <ChangePassword exact /> : <Navigate to="/login" />} />

        <Route path="/bid-request/group/:token" element={<BidRequestGroup />} />
        <Route path="/announcement" element={userInfo ? <AnnouncementScreen exact /> : <Navigate to="/login" />} />
        <Route path="/message-details/:messageId" element={userInfo ? <MessagesScreen exact /> : <Navigate to="/login" />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
