/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppLayout from '../components/utils/layouts/AppLayout';
import ChangePasswordForm from '../components/ChangePasswordForm';

const ChangePassword = () => {
  // const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userDetails);
  const companyId = userDetails?.company?.id;

  return (
    <AppLayout showFooter={false}>
      <ChangePasswordForm />
    </AppLayout>
  );
};

export default ChangePassword;
